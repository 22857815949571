var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "horizontal-tab-bar" },
    [
      _vm.items.length
        ? _c(
            "HorizontalTabBarContent",
            [
              _c(
                "KeepAlive",
                [
                  _vm._t(
                    "content",
                    function() {
                      return [
                        _c(
                          _vm.items[_vm.selectedIndex].content.component,
                          _vm._g(
                            _vm._b(
                              { tag: "Component" },
                              "Component",
                              Object.assign(
                                {},
                                _vm.items[_vm.selectedIndex].content.props || {}
                              ),
                              false
                            ),
                            Object.assign(
                              {},
                              _vm.items[_vm.selectedIndex].content.listeners ||
                                {}
                            )
                          )
                        )
                      ]
                    },
                    { item: _vm.items[_vm.selectedIndex].content }
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }